import React from 'react';
import ProjectImg from '../../assets/images/project-page/pexels-mister-mister-3434523.jpg';
import "./ProjectItem.scss"

export default function ProjectItem({
  img = ProjectImg,
  title = 'Project Name',
  desc = 'This is a Project',
  stack = 'Tech Stack',
  git = 'https://github.com',
  website = '/#',
}) {
  return (
    <section id="main">
      <div className="container_proj">
        <div className="card">
          <div className="imgBx">
            <img src={img} alt="" />
          </div>
          <div className="content">
            <div className="contentBx">
              <h3 style={{ color: '#00008b' }}>{title}<br />
                <span>{stack}
                  <br />
                  {desc}</span>
              </h3>
              <ul className="sci">
                <li >
                  <a rel="noreferrer" href={git} target="_blank"><img alt="github"
                    src="https://img.icons8.com/clouds/60/000000/github.png" /></a>
                </li>
                <li >
                  <a rel="noreferrer" href={website} target="_blank"><img alt="web"
                    src="https://img.icons8.com/clouds/60/000000/domain.png" /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Contact.scss";
import gitImage from "../../assets/images/1fZK.gif";

function Footer() {
    return (
        <Container fluid className="contact_section" id="contact">
            <Container>
                <Row>
                    <Col md={6} className="contact_description">
                        <h1 style={{ fontSize: "2.5em" }} className="contact_left"> &lt; Mohit Lamba/&gt;</h1>
                        <p className="contact_body">
                            "Creativity and passion both comes from trying 100 different things"
                        </p>
                        <br />
                        <img src={gitImage} className="img-dev" alt="avatar" />
                    </Col>

                    <Col md={6}>
                        <h1 className="formLogo" style={{ fontFamily: "Lobster, cursive" }}>Reach Me Out<img alt="Contact"
                            src="https://img.icons8.com/plasticine/50/000000/technical-support.png" /></h1>
                        <form action="https://formspree.io/f/xnqrjlbd" method="POST" className="contact-left">

                            <fieldset>
                                <textarea type="text" className="form_control_input" id="Name"
                                    placeholder="Your name" name="Sender's Name" />
                            </fieldset>
                            <fieldset>
                                <textarea type="email" className="form_control_input" id="Email"
                                    placeholder="Your email" name="Sender's Email" />
                            </fieldset>
                            <fieldset>
                                <textarea className="form_control_textarea" name="Message" id="Message" placeholder="Message"></textarea>
                            </fieldset>
                            <button type="submit" id="formBtn" className="btn" style={{ color: "white" }}>SEND</button>

                        

                        </form>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
export default Footer;

import React, { Component } from 'react'
import "./VolExp.scss";
import GSSOC from "../../assets/images/experience-page/39153675.png";
import SWOC from "../../assets/images/experience-page/1602422883512.jpg";
import CODE from "../../assets/images/experience-page/maxresdefault.jpg";

export class VolExp extends Component {
    render() {
        return (
            <div>
                <div className="vol_exp-Bg">Volunteer Experience</div>
                <h2 className="vol_exp-Fr">Volunteer Experience</h2>
                <div className="cards-list">

                    {/* <div className="card">
                        <div className="card-image">
                            <img alt="LGMSoC" src={LGMSoC} rel="" />
                        </div>
                        <div className="card-title title-black">
                            <p>Mentor & Admin</p>
                        </div>
                    </div> */}

                    <div className="card">
                        <div className="card-image">
                            <img alt="GSSOC" src={GSSOC} rel="" />
                        </div>
                        <div className="card-title title-black">
                            <p>PROJECT ADMIN</p>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-image">
                            <img alt="SWOC" src={SWOC} rel="" />
                        </div>
                        <div className="card-title title-black">
                            <p>PROJECT ADMIN</p>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-image">
                            <img alt="CODE" src={CODE} rel="" />
                        </div>
                        <div className="card-title title-black">
                            <p>MENTOR</p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default VolExp

import React, { Component } from 'react'
import "./Education.scss"
import College from "../../assets/images/education-section/icons8-graduation-cap-100.png"
import School from "../../assets/images/education-section/icons8-school-100.png"
import School10 from "../../assets/images/education-section/icons8-backpack-100.png"

export class Education extends Component {
  render() {
    return (
      <div id="education">
        <h2 className="podcastbg">Education</h2>
        <div className="podcastfr">Education</div>
        <div className="timeline">
          
          <div className="timeline-event timeline-event--type1">
            <div className="timeline-event-icon ">
              <img alt="Graduation" src={College} />
            </div>
            <div className="timeline-event-date">
              2019-2023
              </div>
            <div className="timeline-event-content ">
              <div className="timeline-event-title">
                JAYPEE INSTITUTE OF INFORMATION TECHNOLOGY,NOIDA
                </div>
              <div className="timeline-event-description">
                <p >Bachelor of Technology, Information Technology (2019-2023)</p>
              </div>
            </div>
          </div>

          <div className="timeline-event timeline-event--type2">
            <div className="timeline-event-icon">
              <img alt="School" src={School} />
            </div>
            <div className="timeline-event-date">
              2018
              </div>
            <div className="timeline-event-content">
              <div className="timeline-event-title">
                Bhartiya Vidhyapeeth
                </div>
              <div className="timeline-event-description">
                <p>12th CBSE<br />
                  Score: 82%<br />
                </p>
              </div>
            </div>
          </div>

          <div className="timeline-event timeline-event--type3">
            <div className="timeline-event-icon">
              <img alt="School" src={School10} />
            </div>
            <div className="timeline-event-date">
              2016
              </div>
            <div className="timeline-event-content">
              <div className="timeline-event-title">
                St Peter's College,Agra
                </div>
              <div className="timeline-event-description">
                <p>10th ICSE<br />
                  Score: 80%<br />
                  </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Education

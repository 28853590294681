import React, { Component } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import "./Skills.scss"
import Skill from "../../assets/images/coding-freak.gif"

import Python from "../../assets/images/skill-section/python.png"
import Cpp from "../../assets/images/skill-section/cpp.png"
import C from "../../assets/images/skill-section/c.png"
import Js from "../../assets/images/skill-section/javaScript.png"
import ReactJs from "../../assets/images/skill-section/react.png"
import ReactNative from "../../assets/images/skill-section/react.png"
import Material from "../../assets/images/skill-section/icons8-material-ui-240.png"
import Google from "../../assets/images/skill-section/icons8-google-cloud-240.png"
import SASS from "../../assets/images/skill-section/sass.png"
import Canva from "../../assets/images/skill-section/icons8-canva-200.png"

// Future use
import TypeScript from "../../assets/images/skill-section/typescript.png"
import GoLang from "../../assets/images/skill-section/golang.png"
import NodeJs from "../../assets/images/skill-section/node-js.png"
import Flask from "../../assets/images/skill-section/flask.png"
import Docker from "../../assets/images/skill-section/docker.png"
import Kubernetes from "../../assets/images/skill-section/kubernetes.png"
import Cloud from "../../assets/images/skill-section/google-cloud.png"
import Jenkins from "../../assets/images/skill-section/jenkins.png"
import Flutter from "../../assets/images/skill-section/flutter.png"

// Not Required
import HTML5 from "../../assets/images/skill-section/html5.png"
import CSS3 from "../../assets/images/skill-section/css3.png"
import jQuerry from "../../assets/images/skill-section/jQuery.png"
import Wordpress from "../../assets/images/skill-section/wordpress.png"
import Bootstrap from "../../assets/images/skill-section/bootstrap.png"


export class Skills extends Component {
    render() {
        return (
            <div>
                <h2 className="skillsbg">Skills</h2>
                <div className="skillsfr">Skills</div>
                <Container fluid className="skills_section" id="skills">
                    <Container>
                        <Row>
                            <Col md={4} className="mySkills">
                                <img alt="My skills" src={Skill} className="img_skill" />
                            </Col>

                            <Col md={8} className="skills_description">
                                <p className="skills_intro">Tech I'm familiar with..</p>

                                <div class="skill_images">
                                    <ul>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Python" >
                                                <img className="My_Skill" alt="python" src={Python} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="C++" >
                                                <img className="My_Skill" alt="C++" src={Cpp} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="C" >
                                                <img className="My_Skill" alt="C" src={C} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="JavaScript" >
                                                <img className="My_Skill" alt="JavaScript" src={Js} /></span>
                                        </li>

                                        {/* <li>
                                            <span data-tooltip="down 100" aria-label="TypeScript" >
                                                <img className="My_Skill" alt="TypeScript" src={TypeScript} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Go" >
                                                <img className="My_Skill" alt="Go" src={GoLang} /></span>
                                        </li>

                                        */}

                                        <li>
                                            <span data-tooltip="down 100" aria-label="React Js" >
                                                <img className="My_Skill" id="React-logo" alt="React" src={ReactJs} /></span>
                                        </li>
                                        <li>
                                        <span data-tooltip="down 100" aria-label="material-UI" >
                                            <img className="My_Skill" id="Material" alt="Material-UI" src={Material} /></span>
                                        </li>
                                        <li>
                                        <span data-tooltip="down 100" aria-label="Google-Cloud" >
                                            <img className="My_Skill" id="Google-Cloud" alt="Google-Cloud" src={Google} /></span>
                                        </li>
                                        <li>
                                        <span data-tooltip="down 100" aria-label="React-Native" >
                                            <img className="My_Skill" id="React-Native" alt="React-Native" src={ReactNative} /></span>
                                        </li>

                                        {/* <li>
                                            <span data-tooltip="down 100" aria-label="Flask" >
                                                <img className="My_Skill" alt="Flask" src={Flask} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Docker" >
                                                <img className="My_Skill" alt="Docker" src={Docker} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Kubernetes" >
                                                <img className="My_Skill" alt="Kubernetes" src={Kubernetes} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Cloud" >
                                                <img className="My_Skill" alt="Cloud" src={Cloud} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Jenkins" >
                                                <img className="My_Skill" alt="Jenkins" src={Jenkins} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Flutter" >
                                                <img className="My_Skill" alt="Flutter" src={Flutter} /></span>
                                        </li> */}

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Node Js" >
                                                <img className="My_Skill" alt="Node Js" src={NodeJs} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="SASS" >
                                                <img className="My_Skill" alt="SASS" src={SASS} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Bootstrap" >
                                                <img className="My_Skill" alt="Bootstrap" src={Bootstrap} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="jQuery" >
                                                <img className="My_Skill" alt="jQuery" src={jQuerry} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="HTML 5" >
                                                <img className="My_Skill" alt="HTML5" src={HTML5} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="CSS 3" >
                                                <img className="My_Skill" alt="CSS" src={CSS3} /></span>
                                        </li>

                                        <li>
                                            <span data-tooltip="down 100" aria-label="Wordpress" >
                                                <img className="My_Skill" alt="Wordpress" src={Wordpress} /></span>
                                        </li>
                                        <li>
                                        <span data-tooltip="down 100" aria-label="Canva" >
                                            <img className="My_Skill" alt="Canva" src={Canva} /></span>
                                        </li>



                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        )
    }
}

export default Skills

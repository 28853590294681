import { v4 as uuidv4 } from 'uuid';
import Photos from "../../assets/images/project-page/invaluable-collecting-psychology-hero-v1.0-2x.jpg"
import Todo from "../../assets/images/project-page/20071126-todo-list.png"
import Food from "../../assets/images/project-page/pexels-mister-mister-3434523.jpg"
import COVID from "../../assets/images/project-page/COVID19.png"
import Weather from "../../assets/images/project-page/sea-3449509_1280.jpg"
import Memes from "../../assets/images/project-page/20180703190744-rollsafe-meme.jpeg"

const projects = [
  {
    id: uuidv4(),
    name: 'Medi-Care',
    stacks: '(React Js, Django,ML,Bootstrap)',
    desc:
      'a web application that will  connect the those in need with the correct medical care/supervision',
    img: Photos,
    github: 'https://github.com/mohit200008/medi-Care',
    web: 'https://medi-care-phi.vercel.app/',
  },
  {
    id: uuidv4(),
    name: 'Expenses-App',
    stacks: '(React Native, Expo)',
    desc:
      'Expense Tracking App',
    img: Todo,
    github: 'https://play.google.com/store/apps/details?id=com.expense.RN',
    web: ' ',
  },
  {
    id: uuidv4(),
    name: 'Food Saver',
    stacks: '(React Js, Edamon-api,firebase)',
    desc: 'FoodSaver is a web application that keeps track of the food in your house,& keeps warning you to use the product before it got expires or wasted.',
    img: Food,
    github: 'https://github.com/mohit200008/FoodSaver',
    web: '',
  },
  {
    id: uuidv4(),
    name: "COVID19 Tracker",
    stacks: '(React Js,DISEASE-API,FIREBASE)',
    desc: 'A COVID19 Tracker for tracking live cases for all courtiers.',
    img: COVID,
    github: 'https://github.com/mohit200008/covid-tracker19',
    web: 'https://covid-tracker19-f32d9.web.app/',
  },
  {
    id: uuidv4(),
    name: 'Weather Apllication',
    stacks: '(React Js,axios,Openweathermap-api)',
    desc: 'A weather application telling you the weather and humidity of the desired city.',
    img: Weather,
    github: 'https://github.com/mohit200008/weather-app',
    web: 'https://weather-app-7518e.web.app/',
  },
  {
    id: uuidv4(),
    name: 'Random-Image-Finder',
    stacks: '(React Js, PIXABAY-API)',
    desc:
      'A web application which helps to get images according to the keyword user entered, it was inspired by the sites like pixabay,pexels etc.',
    img: Photos,
    github: 'https://github.com/mohit200008/random-image-finder',
    web: 'https://random-image-finder-94127.web.app/',
  },
  {
    id: uuidv4(),
    name: 'Meme-Generator',
    stacks: '(React Js,Img-flip-API,FIREBASE )',
    desc: 'An application for generating memes and user can enter text and share it.',
    img: Memes,
    github: 'https://github.com/mohit200008/meme-generator',
    web: 'https://ml-meme-gen.netlify.app',
  },
  

];

export default projects;

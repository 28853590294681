import React, { Component } from "react";
import ReactCardCarousel from "react-card-carousel";
import "./Awards.scss"
import CodeOff from "../../assets/images/awards-page/Hackathon (1).png"
import ApScript from "../../assets/images/awards-page/Hackathon.png"
import Gcloud from "../../assets/images/awards-page/goo-cloud.png"
import Hack2Connect from "../../assets/images/awards-page/Screenshot (113).png"
import GSol from "../../assets/images/awards-page/goo-sol.png"
import Covihacks from "../../assets/images/awards-page/JCRL-74FQ-A3UL-CBFA.png"
import Cicada from "../../assets/images/awards-page/Screenshot (114).png"

class Awards extends Component {
  static get CARD_STYLE() {
    return {
      textAlign: "center",
      background: "#404040",
      color: "#FFF",
      fontFamily: "Source Serif Pro, serif",
      fontWeight: "bold",
      letterSpacing: "0.05rem",
      fontSize: "15px",
      borderRadius: "10px",
      boxSizing: "border-box",
    };
  }

  render() {
    return (
      <div id="awards">
        <h2 className="awadsBg">Awards</h2>
        <div className="awadsFg">Awards</div>
        <div className="awards">
          <ReactCardCarousel autoplay={true} autoplay_speed={3500}>
            <div style={Awards.CARD_STYLE}>
              <img className="My__Cert" alt="Google cloud" src={Gcloud} />one of the top perf in google cloud program.
            </div>
            <div style={Awards.CARD_STYLE}>
              <img className="My__Cert" alt="Hack2Connect" src={Hack2Connect} />Winner of the Hack-2-Connect 3.0 Hackathon
          </div>
            <div style={Awards.CARD_STYLE}>
              <img className="My__Cert" alt="covihacks" src={Covihacks} /> Was in top 15 teams out of 150+ teams.
          </div>
            <div style={Awards.CARD_STYLE}>
              <img className="My__Cert" alt="ApScript" src={ApScript} />Finalist of the ApScript Hackathon
          </div>
            <div style={Awards.CARD_STYLE}>
              <img className="My__Cert" alt="Google solution challenge" src={GSol} />Created a solution for the google solution challenge one of the largest challenge by google.
          </div>
          <div style={Awards.CARD_STYLE}>
              <img className="My__Cert" alt="Code off duty" src={CodeOff} /> Was in the top 30 teams out of 200+ teams.
          </div>
          <div style={Awards.CARD_STYLE}>
              <img className="My__Cert" alt="Cicada 3301" src={Cicada} /> Participant and created solution for the hack and was well appreciated.
          </div>
          </ReactCardCarousel>
        </div>
      </div>
    );
  }
}

export default Awards

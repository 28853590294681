import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Tilt from "react-parallax-tilt";
import "./About.scss";
import myImg from "../../assets/images/photo.jpeg"
import Hi from "../../assets/images/Hi.gif"
import Type from "./Type";

function About() {
    return (
        <Container fluid className="about-section" id="about">
            <Container>
                <Row>
                    <Col md={8} className="about-description">

                        <div className="text_center">
                            <h1 style={{ fontSize: "2.6em" }} className="about-intro">Hi <img alt='Hii' src={Hi} width="50px" style={{ marginBottom: "20px" }}></img>, I'm Mohit Lamba</h1>
                            <Type />
                        </div>

                        <p className="about-body" >

                            <main id="container">
                                <div id="terminal">

                                    <section id="terminal__bar">
                                        <div id="bar__buttons">
                                            <button className="bar__button" id="bar__button--exit">&#10005;</button>
                                            <button className="bar__button">&#9472;</button>
                                            <button className="bar__button">&#9723;</button>
                                        </div>
                                        <p id="bar__user">mohit@linux: ~</p>
                                    </section>

                                    <section id="terminal__body">
                                        <div id="terminal__prompt">
                                            <span id="terminal__prompt--user">mohit@linux:</span>
                                            <span id="terminal__prompt--location">~</span>
                                            <span id="terminal__prompt--bling">$ cat about.txt</span>
                                        </div>
                                        <div id="terminal_bio" style={{ textAlign: "left" }}>Hello Mohit Lamba, I hold a Bachelors Degree in Information Technology from JIIT-NOIDA, and I have a passion for coding projects from the ground up, turning ideas into reality within web browsers.<br />
                                            <br />
                                        I am a <i>
                                                <b> Web/App Developer</b></i> and <i><b> and Machine Learning enthusiast.</b>
                                            </i>
                                           

I have a strong affection for experimenting with web design and aspire to craft experiences that bring smiles to peoples faces. My philosophy revolves around exploration, continuous learning, and the creation of novel and distinctive solutions. I eagerly anticipate the opportunity to contribute my skills to something meaningful that can have a profound impact.<br />
                                        </div>
                                       
                                    </section>

                                </div>
                            </main>

                            <a rel="My Resume" href="https://drive.google.com/file/d/1VdUcFmeP_vfQmlWK3yimOFLPw4iuUq35/view?usp=drive_link" target="blank"><div className="btn" style={{ color: "white", marginTop: "1.5rem" }}>My Resume
                            <div className="btn1"></div>
                            </div></a>

                        </p>
                        <br />

                        <div className="text_center">
                            <a rel="Github" href="https://github.com/mohit200008" target="blank" style={{ textDecoration: "none" }}> <img alt="Github"
                                src="https://img.icons8.com/cute-clipart/64/000000/github.png" /> </a>

                            <a rel="Linkedin" href="https://www.linkedin.com/in/mohit-l-a75076108" target="blank"
                            > <img src="https://img.icons8.com/cute-clipart/64/000000/linkedin.png" alt="Linkedin" />
                            </a>

                            <a rel="Medium" href="https://ml-assasn.medium.com/" target="blank" style={{ textDecoration: "none" }}
                            ><img src="https://img.icons8.com/ios-filled/50/000000/medium-monogram--v2.png" alt="Medium" /> </a>

                            <a rel="Stackoverflow" href="https://stackoverflow.com/users/15060808/mohit-lamba" target="blank" style={{ textDecoration: "none" }}> <img src="https://img.icons8.com/color/65/000000/stackoverflow.png" alt="Stackoverflow" /> </a>

                            <a rel="Twitter" href="https://twitter.com/mohitlamba12" target="blank" style={{ textDecoration: "none" }}> <img
                                src="https://img.icons8.com/cute-clipart/65/000000/twitter.png" alt="Twitter" /> </a>

                            <a rel="Instagram" href="https://www.instagram.com/mohit_200008/?hl=en" alt="mohit_200008" target="blank" style={{ textDecoration: "none" }}> <img
                                src="https://img.icons8.com/cute-clipart/64/000000/instagram-new.png" alt="Instagram" /> </a>
                        </div>

                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img src={myImg} className="img-fluid" alt="Me" />
                        </Tilt>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
export default About;

import React, { Component } from 'react'
import "./WorkExperience.scss";
import JODC from "../../assets/images/experience-page/41677221.png"
import TFS from "../../assets/images/experience-page/TSF.png"
import TSPS from "../../assets/images/experience-page/TSPS.png"
import TECH from "../../assets/images/tumblr_c2360ffb1173490a9496463a62e93d23_0fabc917_1280.jpg"
import HCL from "../../assets/images/1577172593_oSU4c8_HCL_tech.jpg"

export class WorkExperience extends Component {
    render() {
        return (
            <div>
                <div className="exp-Bg">Work Experience</div>
                <h2 className="exp-Fr">Work Experience</h2>
                <div className="work-exp">
                    <ul className="work-exp-list">

                        <li>
                            <div className="exp">
                                <img src={TFS} className="exp__image" alt="" />
                                <div className="exp__overlay">
                                    <div className="exp__header">
                                        <svg className="exp__arc" xmlns="http://www.w3.org/2000/svg">
                                            <path />
                                        </svg>
                                        <img className="exp__thumb" alt="Data Science" src="https://img.icons8.com/fluent/100/000000/web-analystics.png" />
                                        <div className="exp__header-text">
                                            <h3 className="exp__title">The Sparks Foundation</h3>
                                            <span className="exp__status">Web development and design Intern<br />
                                            April' 21 - May' 21</span>
                                        </div>
                                    </div>
                                    <p className="exp__description">Learnt how to integrate razorpay payment portal through api and connecting it to my application.</p>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="exp">
                                <img src={TECH} className="exp__image" alt="" />
                                <div className="exp__overlay">
                                    <div className="exp__header">
                                        <svg className="exp__arc" xmlns="http://www.w3.org/2000/svg">
                                            <path />
                                        </svg>
                                        <img alt="Web-Developer" className="exp__thumb" src="https://img.icons8.com/fluent/100/000000/web-analystics.png" />
                                        <div className="exp__header-text">
                                            <h3 className="exp__title">Techtonic Enterprises</h3>
                                            <span className="exp__status">Full Stack Developer Intern <br />
                                            JULY' 21 - September' 21</span>
                                        </div>
                                    </div>
                                    <p className="exp__description">designed the project's front end design and functions, and coordinated with clients to deliver desired products by collaborating with specialists and grads.</p>
                                </div>
                            </div>
                           
                        </li>

                        <li>
                        <div className="exp">
                            <img src={HCL} className="exp__image" alt="" />
                            <div className="exp__overlay">
                                <div className="exp__header">
                                    <svg className="exp__arc" xmlns="http://www.w3.org/2000/svg">
                                        <path />
                                    </svg>
                                    <img alt="Web-Developer" className="exp__thumb" src="https://img.icons8.com/fluent/100/000000/web-analystics.png" />
                                    <div className="exp__header-text">
                                        <h3 className="exp__title">HCL Technoligies</h3>
                                        <span className="exp__status">Backend Developer Intern<br />
                                        JULY' 22 - AUGUST' 22</span>
                                    </div>
                                </div>
                                <p className="exp__description">Worked on contract lifecycle management tool and learnt to work with REST-APIS and SQL.</p>
                            </div>
                        </div>
                       
                    </li>

                        <li>
                            
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}

export default WorkExperience

import React, { Component } from 'react'
import WoekExp from "./WorkExperience"

export class Experience extends Component {
    render() {
        return (
            <div id="experiences">
                <WoekExp />
                
            </div>
        )
    }
}

export default Experience

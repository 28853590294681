import React, { Component } from 'react'
import "./Footer.scss"

export class Footer extends Component {
    render() {
        return (
            <div className="footer_text">&lt;/&gt; with <img alt="love" className="footer_icon" src="https://img.icons8.com/cotton/30/000000/like--v1.png" /> and &nbsp;<img alt="React Js" className="footer_icon" src="https://img.icons8.com/officel/25/000000/react.png" /> by Mohit Lamba</div>
            
        )
    }
}

export default Footer

import React, { useEffect, useState } from 'react';
import "./Projects.scss"
import styled from 'styled-components';
import { MdSearch } from 'react-icons/md';
import ProjectsInfo from './ProjectInfo';
import ProjectItem from './ProjectItem';

const ProjectStyle = styled.div`
.container_project{
      padding: 10rem 0;
      margin-left: 20px;
      margin-right: 20px;
}
  .projects__allItems {
    // margin-top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .projects__searchBar {
    position: relative;
    width: 500px;
    left: -30px;
  }
  .projects__searchBar input {
    width: 100%;
    font-size: 2rem;
    padding: 1.2rem;
    border-radius: 15px;
    outline: none;
    border-style: solid;
  }
  .projects__searchBar .searchIcon {
    position: absolute;
    right: 8.5vw;
    top: 28px;
    padding-top: 5px;
    font-size: 30px;
    color: black;
  }

  input:focus {
	outline: none;
  	border: 2px solid #2196f3;
}
 
  @media only screen and (max-width: 768px) {
    .projects__searchBar,
    .projects__searchBar form {
      width: 100%;
    }
    .projects__searchBar{
        width: 100%;
        left: -80px;
    }
    .projects__searchBar input{
        margin-left: 30px;
        width: 115%;
        padding-right: 55px;
    }
    .projects__searchBar .searchIcon {
        position: absolute;
        left: 76vw;
        padding-top: 5px;
      }
  }
`;

export default function Project() {

    const [searchText, setSearchText] = useState('');
    const [projectsData, setProjectsData] = useState(ProjectsInfo);
    useEffect(() => {
        if (searchText === '') return;
        setProjectsData(() =>
            ProjectsInfo.filter((item) =>
                item.stacks.toLowerCase().match(searchText.toLowerCase())
            )
        );
    }, [searchText]);
    const handleChange = (e) => {
        e.preventDefault();
        setSearchText(e.target.value);
        if (!e.target.value.length > 0) {
            setProjectsData(ProjectsInfo);
        }
    };

    return (
        <>
            <div id="projects">
                <h2 className="projectbg">Projects</h2>
                <div className="projectfr">Projects</div>

                <ProjectStyle>
                    <div className="container_project">
                        <div className="projects__searchBar">
                            <form>
                                <input
                                    type="text"
                                    value={searchText}
                                    onChange={handleChange}
                                    placeholder="Search for tech stack"
                                />
                                <MdSearch className="searchIcon" />
                            </form>
                        </div>
                        <div className="projects__allItems">
                            {projectsData.map((item) => (
                                <ProjectItem
                                    key={item.id}
                                    title={item.name}
                                    desc={item.desc}
                                    stack={item.stacks}
                                    img={item.img}
                                    git={item.github}
                                    website={item.web}
                                />
                            ))}
                        </div>
                    </div>
                </ProjectStyle>

            </div>
            <div className="btn_center">
                <a href="https://github.com/mohit200008" target="blank"><div className="btn" style={{ color: "white" }}>More Projects
                            <div className="btn2"></div>
                </div></a>
            </div>

        </>

    )
}
